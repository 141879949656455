.cover-graphic {
    /* The image used */
    background-image: url("../img/gameplay_1.mp4");

    /* Set a specific height */
    min-height: 500px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
}

.home-body {
    -webkit-box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.47);
    box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.47);

    background-color: var(--default-gray);

}

.home-divider {
    padding-left: 180px;
    padding-right: 180px;
}

.centered-content {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.centered-text {
    text-align: center;
}

.social-panels {
    display: flex;
    flex-direction: row;
    flex-flow: column;
}

.appstore-badge {
    width: 270px;
}

.store-badges {
    flex-direction: row;
    justify-content: center;
}
