@import '~bootstrap/scss/bootstrap';

* {
  font-family: "Dongle", serif;
  font-weight: 400;
  font-style: normal;

  color: white;
}

.default-font,
.default-font-bold {
  font-family: "Dongle", serif;
  font-size: 30pt;
}

.copyright-font {
  font-family: Helvetica, sans-serif;
}

.default-font {
  font-weight: lighter;
}

.default-font-bold {
  font-weight: bold;
}

body {
  background-color: var(--default-gray);
}

.title {
  text-align: center;
  font-size: 100px;
}

.navbar {
  -webkit-box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.47);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.47);
}

.default-link {
  color: white;
  transition: color 0.3s ease 0s;
}

.default-link:hover {
  color: lightgray;
  cursor: pointer;
}

input, select, option {
  color: black !important;
}

.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@import './Page.scss';